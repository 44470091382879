var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Cliente"),(_vm.loadingData)?_c('b-spinner',{staticClass:"ml-3 mb-1",attrs:{"variant":"primary","small":""}}):_vm._e()],1),_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nombre","required":"","name":"name","state":errors[0] ? false : null},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.loadingData},on:{"click":_vm.updateCliente}},[_vm._v("Guardar cambios")])],1)],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Accessos"),_c('b-button',{staticClass:"ml-3",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){_vm.updateAccesoModal = true;_vm.acceso = {name: ''};}}},[_c('plus-icon',{staticClass:"icon-sm mr-2"}),_c('span',[_vm._v("Registrar acceso")])],1)],1),_c('b-table',{staticStyle:{"border-radius":"5px"},attrs:{"items":_vm.accesos,"fields":_vm.accesosFields,"bordered":"","responsive":"","hover":"","no-border-collapse":"","small":"","sticky-header":true,"show-empty":""},on:{"row-clicked":_vm.openAccesosRow},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("No hay accesos creados")])]},proxy:true},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('DD MMMM YYYY, HH:mmA')))])]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA')))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Casas"),_c('b-button',{staticClass:"ml-3",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){_vm.updateCasaModal = true;_vm.casa = {name: ''};}}},[_c('plus-icon',{staticClass:"icon-sm mr-2"}),_c('span',[_vm._v("Registrar casa")])],1)],1),_c('b-table',{staticStyle:{"border-radius":"5px"},attrs:{"items":_vm.casas,"fields":_vm.casasFields,"bordered":"","responsive":"","hover":"","no-border-collapse":"","small":"","sticky-header":true,"show-empty":""},on:{"row-clicked":_vm.openCasasRow},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("No hay casas creados")])]},proxy:true},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('DD MMMM YYYY, HH:mmA')))])]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA')))])]}}],null,true)})],1)],1)],1)]}}])}),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-danger",attrs:{"variant":"link"},on:{"click":_vm.deleteCliente}},[_vm._v("Eliminar cliente")])],1)],1),_c('b-modal',{attrs:{"centered":"","hide-header":"","ok-title":_vm.acceso && _vm.acceso.acceso_id ? 'Guardar cambios' : 'Registrar acceso',"ok-variant":_vm.acceso && _vm.acceso.acceso_id ? 'primary' : 'success',"cancel-title":"Cancelar","ok-disabled":!_vm.acceso || _vm.acceso.name == '' || _vm.loadingData},on:{"hide":_vm.saveAcceso},model:{value:(_vm.updateAccesoModal),callback:function ($$v) {_vm.updateAccesoModal=$$v},expression:"updateAccesoModal"}},[(_vm.acceso)?_c('b-container',[_c('b-form-group',{attrs:{"label":"Nombre de Acceso"}},[_c('b-form-input',{attrs:{"placeholder":"Nombre de Acceso","required":"","name":"name"},model:{value:(_vm.acceso.name),callback:function ($$v) {_vm.$set(_vm.acceso, "name", $$v)},expression:"acceso.name"}})],1)],1):_vm._e()],1),_c('b-modal',{attrs:{"centered":"","hide-header":"","ok-title":_vm.casa && _vm.casa.casa_id ? 'Guardar cambios' : 'Registrar casa',"ok-variant":_vm.casa && _vm.casa.casa_id ? 'primary' : 'success',"cancel-title":"Cancelar","ok-disabled":!_vm.casa || _vm.casa.name == '' || _vm.loadingData},on:{"hide":_vm.saveCasa},model:{value:(_vm.updateCasaModal),callback:function ($$v) {_vm.updateCasaModal=$$v},expression:"updateCasaModal"}},[(_vm.casa)?_c('b-container',[_c('b-form-group',{attrs:{"label":"Nombre de Casa"}},[_c('b-form-input',{attrs:{"placeholder":"Nombre de Casa","required":"","name":"name"},model:{value:(_vm.casa.name),callback:function ($$v) {_vm.$set(_vm.casa, "name", $$v)},expression:"casa.name"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }