<template lang="pug">
  b-container(fluid)
    h3.mb-3 Cliente
      b-spinner.ml-3.mb-1(variant="primary", small, v-if="loadingData")
    div
      validation-observer(v-slot="{invalid, handleSubmit}")
        b-form(@submit.prevent="handleSubmit(save)")
          b-row
            b-col.mb-4(cols="12")
              b-form-group(label="Nombre")
                validation-provider(name="nombre", rules="required|min:1", v-slot="{ errors }")
                  b-form-input(placeholder="Nombre", required, name="name", v-model="client.name", :state="errors[0] ? false : null")
                  b-form-invalid-feedback {{ errors[0] }}
              .text-right
                b-button(variant="primary", :disabled="invalid || loadingData", @click="updateCliente") Guardar cambios

            b-col.mb-4(cols="12")
              h4 Accessos
                b-button.ml-3(size="sm", variant="success", @click="updateAccesoModal = true;acceso = {name: ''};")
                  plus-icon.icon-sm.mr-2
                  span Registrar acceso
              b-table(:items="accesos", :fields="accesosFields", bordered, responsive, hover, no-border-collapse, @row-clicked="openAccesosRow", small, :sticky-header="true", style="border-radius:5px;", show-empty)
                template(#empty)
                  .text-center No hay accesos creados
                template(#cell(createdAt)="{item}")
                  span {{ $moment(item.createdAt).format('DD MMMM YYYY, HH:mmA') }}
                template(#cell(updatedAt)="{item}")
                  span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}

            b-col.mb-3(cols="12")
              h4 Casas
                b-button.ml-3(size="sm", variant="success", @click="updateCasaModal = true;casa = {name: ''};")
                  plus-icon.icon-sm.mr-2
                  span Registrar casa
              b-table(:items="casas", :fields="casasFields", bordered, responsive, hover, no-border-collapse, @row-clicked="openCasasRow", small, :sticky-header="true", style="border-radius:5px;", show-empty)
                template(#empty)
                  .text-center No hay casas creados
                template(#cell(createdAt)="{item}")
                  span {{ $moment(item.createdAt).format('DD MMMM YYYY, HH:mmA') }}
                template(#cell(updatedAt)="{item}")
                  span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}

      .text-right
        b-button.text-danger(variant="link", @click="deleteCliente") Eliminar cliente
      
    b-modal(v-model="updateAccesoModal", centered, hide-header, :ok-title="acceso && acceso.acceso_id ? 'Guardar cambios' : 'Registrar acceso'", :ok-variant="acceso && acceso.acceso_id ? 'primary' : 'success'", cancel-title="Cancelar", :ok-disabled="!acceso || acceso.name == '' || loadingData", @hide="saveAcceso")
      b-container(v-if="acceso")
        b-form-group(label="Nombre de Acceso")
          b-form-input(placeholder="Nombre de Acceso", required, name="name", v-model="acceso.name")

    b-modal(v-model="updateCasaModal", centered, hide-header, :ok-title="casa && casa.casa_id ? 'Guardar cambios' : 'Registrar casa'", :ok-variant="casa && casa.casa_id ? 'primary' : 'success'", cancel-title="Cancelar", :ok-disabled="!casa || casa.name == '' || loadingData", @hide="saveCasa")
      b-container(v-if="casa")
        b-form-group(label="Nombre de Casa")
          b-form-input(placeholder="Nombre de Casa", required, name="name", v-model="casa.name")
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
const clienteController = createNamespacedHelpers('cliente');

export default {
  data () {
    return {
      client: {},
      accesos: [],
      accesosFields: [
        {
          key: 'acceso_id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Nombre acceso'
        },
        {
          key: 'updatedAt',
          label: 'Actualizado'
        },
        {
          key: 'createdAt',
          label: 'Creado'
        }
      ],
      casas: [],
      casasFields: [
        {
          key: 'casa_id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Nombre casa'
        },
        {
          key: 'updatedAt',
          label: 'Actualizado'
        },
        {
          key: 'createdAt',
          label: 'Creado'
        }
      ],
      updateAccesoModal: false,
      acceso: null,
      updateCasaModal: false,
      casa: null
    }
  },
  methods: {
    ...mapActions(['cliente/get', 'cliente/update', 'cliente/registerCasa', 'cliente/registerAcceso', 'casa/update', 'acceso/update', 'cliente/destroy']),
    getCliente () {
      this.loadingData = true;
      this['cliente/get']({
        cliente_id: this.$route.params.cliente_id,
        callback: res => {
          this.loadingData = false;

          if (res.success) {
            this.client = res.resource
            this.casas = res.resource.Casas || []
            this.accesos = res.resource.Accesos || []
          } else {
            this.$bvToast.toast(`Ocurrió un error obteniendo el cliente. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    updateCliente () {
      this.loadingData = true;
      this['cliente/update']({
        cliente_id: this.$route.params.cliente_id,
        name: this.client.name,
        callback: res => {
          this.loadingData = false;
          if (res.success) {
            this.$bvToast.toast(`Cliente actualizado con éxito.`, {
              title: 'Actualizado',
              variant: 'success'
            })
          } else {
            this.$bvToast.toast(`Ocurrió un error actualizando el cliente. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    deleteCliente () {
      this.$bvModal
        .msgBoxConfirm("¿Deseas eliminar el cliente? No se puede deshacer.", {
          okVariant: 'danger',
          okTitle: 'Eliminar'
        })
        .then((value) => {
          if (value == true) {
            this.loadingData = true;
            this['cliente/destroy']({
              cliente_id: this.$route.params.cliente_id,
              callback: res => {
                this.loadingData = false;
                if (res.success) {
                  this.$bvToast.toast(`Cliente eliminado con éxito.`, {
                    title: 'Eliminado',
                    variant: 'success'
                  })

                  setTimeout(() => {
                    this.$router.push({name: 'Clientes'})
                  }, 1500)
                } else {
                  this.$bvToast.toast(`Ocurrió un error eliminando el cliente. Por favor intenta de nuevo.`, {
                    title: 'Ocurrió un error',
                    variant: 'danger'
                  })
                }
              }
            })
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    openAccesosRow (item) {
      this.updateAccesoModal = true;
      this.acceso = item;
    }, 
    saveAcceso (bvModalEvt) {
      if (!bvModalEvt.trigger) bvModalEvt.preventDefault()
      console.log(bvModalEvt.trigger);
      if (bvModalEvt.trigger == 'ok') {
        if (this.acceso && this.acceso.acceso_id) {
          this.updateAcceso(this.acceso)
        } else {
          this.registerAcceso(this.acceso)
        }
      } else {
        this.updateAccesoModal = false;
        this.acceso = null;
      }
    },
    openCasasRow (item) {
      this.updateCasaModal = true;
      this.casa = item;
    },
    saveCasa (bvModalEvt) {
      if (!bvModalEvt.trigger) bvModalEvt.preventDefault()
      if (bvModalEvt.trigger == 'ok') {
        if (this.casa && this.casa.casa_id) {
          this.updateCasa(this.casa)
        } else {
          this.registerCasa(this.casa)
        }
      } else {
        this.updateCasaModal = false;
        this.casa = null;
      }
    },
    registerCasa () {
      this.loadingData = true;
      this['cliente/registerCasa']({
        cliente_id: this.$route.params.cliente_id,
        name: this.casa.name,
        callback: res => {
          this.loadingData = false;
          if (res.success) {
            this.$bvToast.toast(`Casa registrada con éxito.`, {
              title: 'Registrada',
              variant: 'success'
            })

            this.casas.push(res.resource);
            this.updateCasaModal = false;
            this.casa = null;
          } else {
            this.$bvToast.toast(`Ocurrió un error registrando la casa. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    updateCasa (item) {
      this.loadingData = true;
      this['casa/update']({
        casa_id: item.casa_id,
        name: item.name,
        callback: res => {
          this.loadingData = false;
          if (res.success) {
            this.$bvToast.toast(`Casa actualizada con éxito.`, {
              title: 'Actualizada',
              variant: 'success'
            })

            this.updateCasaModal = false;
            let index = this.casas.findIndex(c => c.casa_id == item.casa_id)
            if (index >= 0) this.casas.splice(index, 1, res.resource)
            this.casa = null;
          } else {
            this.$bvToast.toast(`Ocurrió un error actualizando la casa. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    registerAcceso () {
      this.loadingData = true;
      this['cliente/registerAcceso']({
        cliente_id: this.$route.params.cliente_id,
        name: this.acceso.name,
        callback: res => {
          this.loadingData = false;
          if (res.success) {
            this.$bvToast.toast(`Acceso registrado con éxito.`, {
              title: 'Registrado',
              variant: 'success'
            })

            this.accesos.push(res.resource);
            this.updateAccesoModal = false;
            this.acceso = null;
          } else {
            this.$bvToast.toast(`Ocurrió un error registrando el acceso. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    updateAcceso (item) {
      this.loadingData = true;
      this['acceso/update']({
        acceso_id: item.acceso_id,
        name: item.name,
        callback: res => {
          this.loadingData = false;
          if (res.success) {
            this.$bvToast.toast(`Acceso actualizado con éxito.`, {
              title: 'Actualizado',
              variant: 'success'
            })

            this.updateAccesoModal = false;
            let index = this.accesos.findIndex(a => a.acceso_id == item.acceso_id)
            if (index >= 0) this.accesos.splice(index, 1, res.resource)
            this.acceso = null;
          } else {
            this.$bvToast.toast(`Ocurrió un error actualizado el acceso. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
  },
  mounted () {
    this.getCliente()
  }
}
</script>

<style lang="scss" scoped>

</style>